import React from "react";
import { Link } from "react-router-dom";
import brandText from "../../Assets/brand-text.png";
import logo from "../../Assets/logo.png";

export const WelcomeBox = ({ layout = 'login' }) => {
    return (
    <div className='w-full rounded-2xl_next bg-[rgba(251,255,203,0.50)] drop-shadow-[0px_10px_9px_rgba(0,0,0,0.10)] border-[3px] border-[#F9F293] px-4 py-8 lg:px-12 space-y-7 md:space-y-[30px]'>
        <div className='text-center'>
            <img
                src={logo}
                className='w-[90px] md:w-[115px] lg:w-[120px] mx-auto'
                alt=''
            />
        </div>
        <h1 className='w-[280px] mx-auto'>
            <span className='block font-Bungee text-black text-[18px] uppercase mb-3'>
                Welcome to{" "}
            </span>
            <img src={brandText} alt='MyInfinity' />
        </h1>

        <div className='text-center md:!mt-[50px]'>
            {layout === 'login' ? 
                <>
                    <p className='font-semibold text-black text-base mb-[10px]'>
                        New here?
                    </p>
                    <Link to={'/register'} className='bg-btn-gradient-gold font-Bungee font-normal text-black uppercase text-sm leading-tight px-4 py-4 inline-block drop-shadow-btn border border-black rounded-lg w-[280px]'>
                        Create an account first
                    </Link>
                </>
            :
                <>
                    <p className='font-semibold text-black text-base mb-[10px]'>
                        Already Registered?
                    </p>
                    <Link to={'/login'} className='bg-btn-gradient-gold font-Bungee font-normal text-black uppercase text-sm leading-tight px-4 py-4 inline-block drop-shadow-btn border border-black rounded-lg w-[280px]'>
                        Login to Dashboard
                    </Link>
                </>
            }
        </div>
    </div>
    );
}
