import { NumericStepper } from '@anatoliygatt/numeric-stepper';
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { BarLoader } from 'react-spinners';
import Swal from "sweetalert2";
import coin2 from "../../../Assets/coin2.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useCallAPI } from "../../../Hooks/callAPI";
import { PendingModal } from "../../../Hooks/pendingModal";
import { usePollAPI } from "../../../Hooks/pollAPI";
import { useReadContract } from "../../../Hooks/readContract";
import { useWriteContract } from "../../../Hooks/writeContract";
import { ButtonTwo } from '../../../Utility/Buttons';
import { CardGold, TitleSecondary } from '../../../Utility/Utility';

const PurchasePack = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const writeContract = useWriteContract();
  const readContract = useReadContract();
  const pollResults = usePollAPI();
  const callAPI = useCallAPI();

  const [userBalance, setUserBalance] = useState(false);
  const [blockDiv, setBlockDiv] = useState('');
  const [packCount, setPackCount] = useState(1);
  const [packCost, setPackCost] = useState(0);
  const [packCostBNB, setPackCostBNB] = useState(0);
  const [packLimit, setPackLimit] = useState(1);
  const [purchaseCooldown, setPurchaseCooldown] = useState(0);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getBalances();
    }
  }, [auth, walletContext.walletConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
    const packCostRes = await readContract.read('PACK_COST');
    setPackCost(packCostRes.toString());
    const packCostBNBRes = await readContract.getCost(packCostRes.toString());
    setPackCostBNB(packCostBNBRes);
    const purchaseRules = await readContract.read('getPurchaseRulesData', [ walletContext.userAddress ] );
    setPurchaseCooldown(purchaseRules[0].toString()*1);
    setPackLimit(purchaseRules[1].toString()*1);
  }

  const startPurchase = async () => {
      setBlockDiv('purchaseBtn');
      // DO prelim checks
      try {

          if(!walletContext.walletConnected) {
              await walletContext.connectWallet();
          }
          if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }
          if(walletContext.userAddress.toLowerCase() != auth.userBootstrap.userData.address.toLowerCase()) { throw 'Switch to your registered wallet address'; }

          //If Presale active allow only 1 purchase per wallet
          const launched = await readContract.read('LAUNCHED_ON');
          if(launched.toString()*1 === 0) {
            //Check if user is whitelisted
            const isWhiteListed = await readContract.read('whitelistedAddress', [ walletContext.userAddress ]);
            if(isWhiteListed) {
              //Check if 1 pack already bought. Show error
              if(auth?.userBootstrap.userTotalPacks*1 >= 1) {
                throw 'Only 1 pack allowed till Official launch'; 
              }
            } else {
              throw 'Please wait for Official Launch'; 
            }
          }

          //Limit maximium packsCount to limit set in contract
          if(packCount*1 > packLimit) {
            throw 'Cannot buy more than ' + packLimit + ' packs at once.'; 
          }

          const cost = await readContract.getCost(packCost*packCount);
          if(userBalance.int < cost.int) { throw 'Insufficient Balance'; }

          purchaseNow(cost);

      } catch (error) {
          console.log(error);
          toast.error(error);
          setBlockDiv('');
      }
  }

  const purchaseNow = async (cost) => {
      setBlockDiv('purchaseBtn');
      await writeContract.ContractWrite({ writeFn: "purchasePacks", inputData: [ packCount.toString() ], successCallback: purchaseCB, value: cost.bns});
      setBlockDiv('');
  }

  const purchaseCB = (hash) => {
      return new Promise(resolve => {
          (async () => {
              const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
              const pollContinueCondition = (res) => res.data.state === "pending";
              const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
              resolve();
              if(pollRes.data.state == "success") {
                  //Show success popup
                  Swal.fire({
                      title: "Purchase Complete!",
                      text: "Purchase completed successfully.",
                      icon: 'success'
                  });
                  await auth.getAppBootstrap();
              } else if(pollRes.data.state == "failed") {
                  toast.error("Failed to purchase.");
              }
          })();
      });
  }

  return (
    <CardGold>
      <div className="p-6 relative">
        <img
          className="absolute right-0 sm:right-14 -top-8 w-[80px] sm:w-[110px]"
          src={coin2}
          alt="coin"
        />
        <div className="black mb-8 sm:mb-14">
          <TitleSecondary>Purchase Packs</TitleSecondary>
        </div>
        <div className="flex items-center justify-between mb-8">
          <p className="text-black font-Bungee text-4xl m-0">${packCost/100} <span className='text-sm font-inria-sans'>Per Pack</span></p>
          <NumericStepper
              minimumValue={1}
              stepValue={1}
              maximumValue={packLimit}
              initialValue={packCount}
              size='sm'
              inactiveTrackColor="#222222"
              activeTrackColor="#333333"
              activeButtonColor="#ffedd5"
              inactiveIconColor="#fb923c"
              hoverIconColor="#ea580c"
              activeIconColor="#9a3412"
              disabledIconColor="#fdba74"
              thumbColor="#fdba74"
              thumbLabelColor='#333333'
              thumbShadowAnimationOnTrackHoverEnabled={true}
              focusRingColor="#fff7ed"
              onChange={setPackCount}
          />
        </div>
        {writeContract.modalShow ?
          <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
        :
          <>
            {packCostBNB.int > 0 ?
              <>
                {purchaseCooldown > new Date() / 1000 ?
                  <Button className='red mb-10' color='black' variant='outlined'>Purchase again in <Countdown date={purchaseCooldown*1000} onComplete={getBalances} /></Button>
                  :
                  <>
                    <ButtonTwo onClick={startPurchase} loading={blockDiv === 'purchaseBtn' ? 1 : 0}>
                      Purchase Now
                      <span>${packCount * packCost / 100}</span>
                    </ButtonTwo>
                    <p className="text-center text-black text-sm font-medium tracking-[.6px] mt-5">
                      You will spend {(packCostBNB.int * packCount).toFixed(4)} BNB for this purchase
                    </p>
                  </>
                }
              </>
            :
              <BarLoader
                color={'#333'}
                className="mx-auto mt-4"
              />
            }
          </>
        }
      </div>
    </CardGold>
  );
};

export default PurchasePack;
