import React from "react";
const Statistics = ({ title, value, todayValue, bg, icon }) => {
  let cardBg = "",
    textColor = "",
    hr = "",
    border = "";
  if (bg && bg === "gold") {
    cardBg = "bg-gradient-gold";
    textColor = "text-black";
    hr = "bg-[rgba(46,46,45,0.9)]";
    border = "border-[rgba(46,46,45,0.9)]";
  } else {
    cardBg = "bg-black";
    textColor = "text-white";
    hr = "bg-gradient-gold";
    border = "border-secondary";
  }
  return (
    <div
      className={`${cardBg} border-[3px] ${border} shadow-one rounded-2xl_next`}
    >
      <div className="flex justify-between align-middle items-center space-x-5 px-5 py-4">
        <div className={`${textColor}`}>
          <strong className="block text-[28px] font-bold">
            {value}
          </strong>
          <span className="block text-[14px] font-normal tracking-[.48px] opacity-60">
            {title}
          </span>
        </div>
        <div className={`rounded-full p-3 ${bg === "gold" ? 'bg-[#000]' : 'bg-[#333]'}`}>
          <img className="max-w-[50px]" src={icon} alt="icon" />
        </div>
      </div>
      <div className={`${hr} w-full h-[3px]`}></div>
      <div className="px-5 py-3">
        <p className={`${textColor} text-sm tracking-[.48px] m-0`}>
          {todayValue}
        </p>
      </div>
    </div>
  );
};

export default Statistics;
