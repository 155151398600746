import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { publicProvider } from '@wagmi/core/providers/public'
import { jsonRpcProvider } from '@wagmi/core/providers/jsonRpc'
import { Web3Modal } from '@web3modal/react';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter as Router } from "react-router-dom";
import { WagmiConfig, configureChains, createConfig } from 'wagmi';
import { bsc, bscTestnet, mainnet } from 'wagmi/chains';
import ScrollToTop from './Contexts/ScrollToTop';
import { AppStateProvider } from './Contexts/appStateContext';
import { UserAuthProvider } from './Contexts/authContext';
import { WalletContextProvider } from './Contexts/walletContext';
import Layout from './Routers/Layout';
import appConfig from './Utility/Config/config';
import { setupIonicReact } from '@ionic/react';
import { useEffect, useState } from 'react';

setupIonicReact();

function App() {
  const chains = [ appConfig.testnet ? bscTestnet : bsc ];
  const projectId = appConfig.walletConnectKey;
  const [loadWeb3Modal, setLoadWeb3Modal] = useState(false);

  let allProviders = [
    jsonRpcProvider({
      rpc: (chain) => ({
        http: appConfig.customRPCURL,
      }),
    }), 
    w3mProvider({ projectId }), 
    publicProvider()
  ];

  if(appConfig.testnet) {
    allProviders = [
      w3mProvider({ projectId }), 
      publicProvider()
    ];
  }

  const { publicClient } = configureChains(chains, allProviders);
  const wagmiConfig = createConfig({
    autoConnect: true,
    connectors: w3mConnectors({ projectId, chains }),
    publicClient
  })
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  useEffect(() => {
    setLoadWeb3Modal(true);
  }, [])
  return (
    <>
      <AppStateProvider>
        <UserAuthProvider>
          {loadWeb3Modal && 
            <WagmiConfig config={wagmiConfig}>
              <WalletContextProvider>
                <Router>
                  <ScrollToTop />
                  <Layout />
                </Router>
                <Toaster position="top-right" toastOptions={{
                  className: '',
                  style: {
                    border: '1px solid #222',
                    background: '#222',
                    color: '#fff',
                    fontSize: '16px',
                    padding: '13px 20px',
                  },
                }}/>
              </WalletContextProvider>
            </WagmiConfig>
          }
        </UserAuthProvider>
      </AppStateProvider>
      <Web3Modal projectId={projectId} defaultChain={appConfig.testnet ? bscTestnet : bsc} ethereumClient={ethereumClient} />
    </>
  );
}

export default App;
