import { Chip } from "@material-tailwind/react";
import React from "react";
import { useHelpers } from "../../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../../Utility/Utility";

const PaymentsTable = ({ data }) => {

    const helper = useHelpers();

    console.log(data);
    
    return (
        <>
            <div className="mb-6">
                <TitlePrimary>Pack Cycles</TitlePrimary>
            </div>
            {data && data.length > 0 &&
                <>
                    <div className="h-full w-full overflow-scroll shadow-xl border-2 border-dark rounded-10">
                        <table className="w-full min-w-max table-auto text-left overflow-hidden text-gray-200">
                            <thead>
                                <tr className="bg-gradient-gold text-gray-900">
                                    <th className="text-sm py-4 px-5">From User</th>
                                    <th className="text-sm py-4 px-5">Pack #</th>
                                    <th className="text-sm py-4 px-5">Amount</th>
                                    <th className="text-sm py-4 px-5">Type</th>
                                    <th className="text-sm py-4 px-5">Hash</th>
                                    <th className="text-sm py-4 px-5">Date</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data && data.length > 0 && data.map((e, i) => {
                                return (
                                    <tr key={i} className={`odd:bg-[#000] bg-[#111] border-b border-[rgb(8,14,26)] text-sm hover:bg-gradient-gold hover:text-[#000]`}>
                                        <td className="px-5 py-4">{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                        <td className="px-5 py-4">#{e.pack_id}</td>
                                        <td className="px-5 py-4"><helper.AmountToCurrency amount={e.amount} /></td>
                                        <td className="px-5 py-4"><Chip className="inline" color='amber' value={helper.capitalize(e.type)} /></td>
                                        <td className="px-5 py-4"><Chip className="inline" color='gray' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                        <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {data && data.length === 0 &&
                <EmptyRecords title='No Payments Found' description='Your payments will be listed here.' />
            }
            {data === false &&
                <LoadingData />
            }
        </>
    );
};

export default PaymentsTable;
