import { Link } from "react-router-dom";

export const NavDash = () => {
  return (
    <>
        <li className="active">
          <Link to="/">Dashboard</Link>
        </li>
        <li>
          <Link to="/packs">Packs</Link>
        </li>
        <li>
          <Link to="/referrals">Referrals</Link>
        </li>
        <li>
          <Link to="/payments">Payments</Link>
        </li>
        <li>
          <Link to="/activities">Activity</Link>
        </li>
        <li>
          <Link to="/logout">Logout</Link>
        </li>
    </>
  );
};