import React from "react";
import AppRouter from "./AppRouter";
import HeaderDash from "../Components/dash/layout/HeaderDash";
import bgDashboard from "../Assets/bg-dashboard-pge.webp";
import ComingSoon from "../Components/dash/dashboard/ComingSoon";
import Invalidchain from "../Components/others/InvalidChain";

const Dashboardlayout = () => {

  return (
    <div className="bg-[#0B002B]">
      <Invalidchain />
      <HeaderDash />
      <main className="overflow-hidden relative px-4 py-6 md:p-6 min-h-screen">
        <div
          className="w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center z-0"
          style={{ backgroundImage: `url(${bgDashboard})`, backgroundAttachment: 'fixed', backgroundPosition: '0 60%' }}
        ></div>
        {/* <ComingSoon /> */}
        <AppRouter />
      </main>
    </div>
  );
};

export default Dashboardlayout;
