import { default as React } from "react";
import bgLoginPge from "../Assets/bg-login-pge.webp";
import AppRouter from "./AppRouter";
import Invalidchain from "../Components/others/InvalidChain";

export default function AuthLayout() {  
  return (
    <div className='min-h-screen bg-[#0B002B] relative font-inria-sans'>
      <Invalidchain />
      <div
        className='w-full h-full absolute top-0 start-0 bottom-0 end-0 bg-cover bg-center'
        style={{ backgroundImage: `url(${bgLoginPge})` }}
      ></div>
      <div className='min-h-screen flex flex-col justify-center z-10 relative py-8'>
        <main>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-4 lg:gap-8 xl:gap-16 max-w-[1080px] mx-auto px-4 lg:px-8'>
            <AppRouter />
          </div>
        </main>
      </div>
    </div>
  );
}