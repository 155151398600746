import { useWeb3Modal } from "@web3modal/react";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useHelpers } from "../../../Hooks/helpers";
import { useReadContract } from "../../../Hooks/readContract";
import ShortHeaderInfo from "./ShortHeaderInfo";
import bnb from "../../../Assets/bnb.png";
import wallet from "../../../Assets/wallet.png";
import user from "../../../Assets/user.png";
import { BarLoader, PropagateLoader } from "react-spinners";

const UserDetailsDash = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const helper = useHelpers();
  const web3modal = useWeb3Modal();
  const readContract = useReadContract();
  
  const [userBalance, setUserBalance] = useState(false);

  //Get user balance
  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getBalances();
    }
  }, [auth, walletContext.walletConnected]);

  const getBalances = async () => {
    setUserBalance(await readContract.getUserBalance());
  }
  
  return (
    <>
        <ShortHeaderInfo icon={bnb} title="BNB Balance" value={userBalance ? (userBalance?.int).toFixed(3) + ' BNB' : <BarLoader className="mt-2" color="#444" />} />
        <ShortHeaderInfo icon={wallet} title="Wallet" value={walletContext?.userAddress && <helper.trimAddress address={walletContext?.userAddress} copy />} />
        <ShortHeaderInfo icon={user} title="User ID" value={auth?.userBootstrap?.userData?.id} />     
    </>
  );
};

export default UserDetailsDash;