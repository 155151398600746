import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AccountDetails from "../../Components/dash/dashboard/AccountDetails";
import PurchasePack from "../../Components/dash/dashboard/PurchasePack";
import ReferDashBlock from "../../Components/dash/dashboard/ReferDashBlock";
import Statistics from "../../Components/dash/dashboard/Statistics";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { TitlePrimary } from "../../Utility/Utility";
import u1 from '../../Assets/StatIcons/u1.png';
import u2 from '../../Assets/StatIcons/u2.png';
import u3 from '../../Assets/StatIcons/u3.png';
import u4 from '../../Assets/StatIcons/u4.png';
import u5 from '../../Assets/StatIcons/u5.png';
import u6 from '../../Assets/StatIcons/u6.png';
import u7 from '../../Assets/StatIcons/u7.png';
import g1 from '../../Assets/StatIcons/g1.png';
import g2 from '../../Assets/StatIcons/g2.png';
import g3 from '../../Assets/StatIcons/g3.png';
import g4 from '../../Assets/StatIcons/g4.png';
import SetUsername from "../../Components/dash/dashboard/SetUsername";
import ActivePacks from "../../Components/dash/dashboard/ActivePacks";

const Dashboard = () => {

  const auth = useUserAuth();
  const history = useNavigate();
  const walletContext = useWalletContext();
  const callAPI = useCallAPI();

  const [stats, setStats] = useState(false);

  //Get user balance
  useEffect(() => {
      if(auth.appBootstrapped === true && auth.loggedIn === false) {
        history('/login');
        return;
      }
      if(walletContext.walletConnected === true) {
          getResults();
      }
  }, [auth, walletContext.walletConnected]);

  const getResults = async () => {
      const res = await callAPI("dashboard");      
      if(res.status && res.data) {
          setStats(res.data);
      } else {
          setStats([]);
      }
  }

  return (
    <div className=" relative z-10">
      {stats && <ActivePacks stats={stats} />}
      <div className="space-y-8 xl:space-y-0 xl:grid xl:grid-cols-[400px_auto] 2xl:grid-cols-[500px_auto] gap-8">
        <div>
          <TitlePrimary>Dashboard</TitlePrimary>
          <div className="space-y-6">
            <PurchasePack />
            <AccountDetails />
          </div>
        </div>
        <div className="space-y-12">
          <div>
            <TitlePrimary>My Statistics</TitlePrimary>
            <div className="grid grid-cols-1 sm:grid-cols-3 2xl:grid-cols-3 gap-6">
              {stats && 
                <>
                  <Statistics bg="gold" title="Total Purchased Packs" value={stats.userStats.totalPurchasedPacks} todayValue={stats.userStats.totalPurchasedPacksToday + ' today'} icon={u2} />
                  <Statistics bg="blcak" title="Total Active Packs" value={stats.userStats.totalActivePacks} todayValue={stats.userStats.totalExpiredPacks + ' packs expired'} icon={u1} />
                  <Statistics bg="gold" title="Total Packs Created by Cycle" value={stats.userStats.totalPacksByCycle} todayValue={stats.userStats.totalPacksByCycleToday + ' today'} icon={u3} />
                  <Statistics bg="black" title="Purchased Packs Cycled" value={stats.userStats.packsCycled} todayValue={stats.userStats.packsCycledToday + ' today'} icon={u4} />
                  <Statistics bg="gold" title="Leg Payments Received" value={'$' + stats.userStats.legPaymentsReceived} todayValue={'$' + stats.userStats.legPaymentsReceivedToday + ' today'} icon={u5} />
                  <Statistics bg="black" title="FastStart Payments Received" value={'$' + stats.userStats.fastStartEarnings} todayValue={'$' + stats.userStats.fastStartEarningsToday + ' today'} icon={u6} />
                  <Statistics bg="gold" title="FastStart Payments Missed" value={'$' + stats.userStats.missedFastStartEarnings} todayValue={'$' + stats.userStats.missedFastStartEarningsToday + ' today'} icon={u7} />
                  <Statistics bg="black" title="Total Referrals" value={stats.userStats.totalReferrals} todayValue={stats.userStats.referredToday + ' today'} icon={u1} />
                </>
              }
            </div>
          </div>
          <div className="md:grid grid-cols-[30%_70%] md:space-x-6 space-y-6 md:space-y-0">
            <SetUsername />
            <ReferDashBlock />
          </div>
          <div>
            <TitlePrimary>Global Statistics</TitlePrimary>
            <div className="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-6">
              {stats && 
                <>
                  <Statistics bg="black" title="Total Users" value={stats.siteStats[0].cacheValue*1 + stats.siteStats[8].cacheValue*1} todayValue={(stats.siteStats[1].cacheValue*1 + stats.siteStats[9].cacheValue*1) + ' today'} icon={g1} />
                  <Statistics bg="gold" title="Total Packs" value={stats.siteStats[2].cacheValue} todayValue={stats.siteStats[3].cacheValue + ' today'} icon={g2} />
                  <Statistics bg="black" title="Total Leg Payments Earned" value={'$' + stats.siteStats[4].cacheValue} todayValue={'$' + stats.siteStats[5].cacheValue + ' today'} icon={g3} />
                  <Statistics bg="gold" title="Total FastStart Earned" value={'$' + stats.siteStats[6].cacheValue} todayValue={'$' + stats.siteStats[7].cacheValue + ' today'} icon={g4} />
                </>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
