import { Button } from "@material-tailwind/react";
import { BarLoader } from "react-spinners";

export const BaseButton = ({ children, ...props }) => {
  return (
    <Button {...props}>
      {!props.loading ?
        <>{children}</>
      :
        <BarLoader
          color={props.loadercolor}
          loading={true}
          size={props.loadersize ? props.loadersize : 15}
          aria-label="Loading"
          data-testid="loader"
          className="mx-auto"
        />
      }
    </Button>
  );
}

export const ButtonOne = ({ children, ...props }) => {
    return (
      <BaseButton
        color="gray"
        {...props}
        loadercolor="#fff"
        className={`text-shadow-one w-full text-md bg-btn-gradient-black rounded-lg font-Bungee font-normal tracking-[1.4px] uppercase text-base_next leading-tight px-6 py-4 inline-block drop-shadow-btn border border-black disabled:opacity-50 text-primary ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
      >
        {children}
      </BaseButton>
    );
}

export const ButtonTwo = ({ children, ...props }) => {
  return (
    <BaseButton
      color="gray"
      {...props}
      loadercolor="#fff"
      className={`bg-btn-gradient-black rounded-lg font-Bungee font-normal tracking-[1.4px] uppercase text-lg leading-tight px-6 py-5 drop-shadow-btn border border-black inline-flex justify-between w-full text-shadow-one text-primary ${props.loading ? 'opacity-50 pointer-events-none' : ''}`}
    >
      {children}
    </BaseButton>
  );
}