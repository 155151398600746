import React, { useEffect, useState } from "react";
import { CardBlack, TitleSecondary } from "../../../Utility/Utility";
import { useUserAuth } from "../../../Contexts/authContext";
import { useWalletContext } from "../../../Contexts/walletContext";
import { useHelpers } from "../../../Hooks/helpers";

const AccountDetails = () => {

  const auth = useUserAuth();
  const walletContext = useWalletContext();
  const helper = useHelpers();

  const [stats, setStats] = useState([]);

  useEffect(() => {
    if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
        getStats();
    }
  }, [auth, walletContext.walletConnected]);

  const getStats = () => {
    setStats([
      {
        title: 'Username',
        value:  auth?.userBootstrap?.userData?.username,
      },
      {
        title: 'Registered Wallet',
        value: <helper.trimAddress address={auth?.userBootstrap?.userData?.address} copy={true} />,
      },
      {
        title: 'Connected Wallet',
        value: <helper.trimAddress address={walletContext?.userAddress} copy={true} />,
      },
      {
        title: 'Referrer ID',
        value: '#' + auth?.userBootstrap?.userReferrerData?.id,
      },
      {
        title: 'Referrer Username',
        value: auth?.userBootstrap?.userReferrerData?.username,
      },
      {
        title: 'Referrer Wallet',
        value: <helper.trimAddress address={auth?.userBootstrap?.userReferrerData?.address} copy={true} />,
      },
    ]);
  }
  
  return (
    <CardBlack>
      <div className="text-center py-3 px-5">
        <TitleSecondary>My Account Details</TitleSecondary>
      </div>
      <div>
        <ul>
          {stats.map((row, index) => {
            return (
              <li
                key={index}
                className="odd:bg-[#2D2B21] grid grid-cols-2 gap-4 text-white tracking-[.6px] font-semibold text-base px-6 py-4"
              >
                <span className="block font-normal text-sm">{row.title}</span>
                <span className="block text-right text-sm">{row.value}</span>
              </li>
            );
          })}
        </ul>
      </div>
    </CardBlack>
  );
};
export default AccountDetails;
