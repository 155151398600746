//Import CSS
import ClipLoader from "react-spinners/ClipLoader";
import bgLoginPge from "../Assets/bg-login-pge.webp";
import { PropagateLoader } from "react-spinners";

export default function LayoutLoading() {  
    return (
        <div className="grid h-screen place-items-center bg-[#0B002B]" style={{ backgroundImage: `url(${bgLoginPge})` }}>
            <PropagateLoader
              color={'#000'}
              loading={true}
              aria-label="Loading"
              data-testid="loader"
            />
        </div>
    );
}