import { fetchBalance, readContract as readWagmiContract } from '@wagmi/core';
import { formatEther } from 'viem';
import { useUserAuth } from "../Contexts/authContext";
import { useWalletContext } from '../Contexts/walletContext';
import contractAbi from '../Utility/ABI/main.json';

export const useReadContract = () => {

    const authContext = useUserAuth();
    const walletContext = useWalletContext();

    const contractObj = async (contractAddr, abi, methodName, methodParams) => {
        const data = await readWagmiContract({
            address: contractAddr,
            abi: abi,
            functionName: methodName,
            args: methodParams,
        });
        return data;
    }

    const read = async (method, params) => {
        const res = await contractObj(authContext.systemSettings.CONTRACT_ADDRESS, contractAbi, method, params);
        return res;
    }

    const getCost = async (amountWei) => {
        const res = await read('getCost', [amountWei.toString()]);
        return { bign: res, int: formatEther(res.toString())*1, bns: res.toString() };
    }

    const getUserBalance = async () => {
        const res = await fetchBalance({
            address: walletContext.userAddress
        });
        return { bign: res.value, int: formatEther(res.value)*1, bign: res.value.toString() };
    }

    const getContractBalance = async () => {
        const res = await fetchBalance({
            address: authContext.systemSettings.CONTRACT_ADDRESS
        });
        return { bign: res.value, int: formatEther(res.value)*1, bign: res.value.toString() };
    }

    return {read, getCost, getUserBalance, getContractBalance};
}