import React, { useEffect, useState } from "react";
import PackItem from "../../Components/dash/packs/PackItem";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Packs = () => {

    const auth = useUserAuth();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [packs, setPacks] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults();
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async () => {        
        const res = await callAPI("getPacks");     
        if(res.status && res.data) {
            setPacks(res.data);
        } else {
            setPacks([]);
        }
    }

    return (
        <div className="relative z-10">
            {packs && packs.length > 0 &&
                <div>
                    <TitlePrimary>My Purchased Packs</TitlePrimary>
                    <div className="sm:grid md:grid-cols-2 xl:grid-cols-3 3xl:grid-cols-4 gap-7">
                        {packs && packs.length > 0 && packs.map((e, i) => {
                            return (
                                <div className="relative" key={i} >
                                    <PackItem pack={e} showButton={true} />
                                </div>
                            )
                        })}
                    </div>
                </div>
            }
            {packs && packs.length === 0 &&
                <EmptyRecords title='No Packs Found' description='Purchased Packs will be listed here.' />
            }
            {packs === false &&
                <LoadingData />
            }
        </div>
    );
};

export default Packs;
