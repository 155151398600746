const runDevAsProd = true;
const settings = {
    testNet: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false, //Change to false for production
    useDevServer: (process.env.NODE_ENV === 'development' && !runDevAsProd)?true:false //Change to false for using production servers
}
const appConfig = {
    version: '1.1.3',
    appLink: 'https://app.myinfinity.io/',
    websiteLink: 'https://www.myinfinity.io',
    testnet: settings.testNet,
    walletConnectKey: '189092d86ae6a70a862c92e7d26d0f9f',
    customRPCURL: 'https://sparkling-withered-tree.bsc.quiknode.pro/38d9c5b6b2686bac2f3a9615396c19ae4e95526b/', //admin@spicydevs.com
    apiEndpoint: settings.useDevServer?'https://dev.myinfinity.io/app/':'https://api.myinfinity.io/app/',
    supportedChain: settings.testNet?'97':'56',
    supportedChainName: settings.testNet?'Binance Smart Chain Testnet':'Binance Smart Chain Mainnet',
    scanURL: settings.testNet?'https://testnet.bscscan.com/':'https://bscscan.com/',
    gaSiteCode: "G-CTDVL8W4LS",

    //URLs
    complan: 'https://www.myinfinity.io/complan.pdf',
    support: 'https://myinfinity.freshdesk.com/',
    telegram: 'https://t.me/MyInfinityio',
    youtube: 'https://www.youtube.com/watch?v=njh4A2tig1M&t=3s',
    ytChannel: 'https://www.youtube.com/@myinfinityofficial'
}

export default appConfig;