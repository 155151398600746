import React from "react";
import {
  Navigate,
  Route,
  Routes
} from "react-router-dom";

//Import pages
import Login from "../Pages/auth/Login";
import Logout from "../Pages/auth/Logout";
import Register from "../Pages/auth/Register";
import Activities from "../Pages/dash/Activities";
import Dashboard from "../Pages/dash/Dashboard";
import EbooksProduct from "../Pages/dash/EbooksProduct";
import PackDetails from "../Pages/dash/PackDetails";
import Packs from "../Pages/dash/Packs";
import Payments from "../Pages/dash/Payments";
import Referrals from "../Pages/dash/Referrals";
import TelegramAlerts from "../Pages/dash/TelegramAlerts";

export default function AppRouter() {
  return (
    <Routes>
        {/* Dashboard Routes */}
        <Route exact path="/" element={<Navigate to="/dashboard" />}></Route>

        <Route path="/dashboard" element={<Dashboard />}></Route>
        <Route path="/packs" element={<Packs />}></Route>
        <Route path="/packs/:id" element={<PackDetails />}></Route>
        <Route path="/referrals" element={<Referrals />}></Route>
        <Route path="/activities" element={<Activities />}></Route>
        <Route path="/payments" element={<Payments />}></Route>
        <Route path="/tgAlerts" element={<TelegramAlerts />}></Route>
        <Route path="/ebooks" element={<EbooksProduct />}></Route>

        {/* Authentication Routes */}
        <Route path="/register" element={<Register />}></Route>
        <Route path="/login" element={<Login />}></Route>
        <Route path="/login/autoLogin" element={<Login autoLogin={true} />}></Route>
        <Route path="/logout" element={<Logout />}></Route>

        {/* //Referral LInk */}
        <Route path="/register/:ref" element={<Register />}></Route>
        <Route path="/home/:ref" element={<Register takeToHome={true} />}></Route>

    </Routes>
  );
}