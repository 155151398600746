import { yupResolver } from '@hookform/resolvers/yup';
import { useWeb3Modal } from '@web3modal/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import * as yup from 'yup';
import lock from "../../Assets/lock.png";
import transLock from "../../Assets/trans-lock.png";
import { WelcomeBox } from '../../Components/auth/WelcomeBox';
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from '../../Contexts/walletContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { PendingModal } from '../../Hooks/pendingModal';
import { usePollAPI } from '../../Hooks/pollAPI';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import { ButtonOne } from '../../Utility/Buttons';
import appConfig from '../../Utility/Config/config';

const Register = ({ takeToHome = false }) => {

    const callAPI = useCallAPI();
    const history = useNavigate();
    const walletContext = useWalletContext();
    const authContext = useUserAuth();
    const params = useParams();
    const writeContract = useWriteContract();
    const readContract = useReadContract();
    const helpers = useHelpers();
    const pollResults = usePollAPI();
    const web3modal = useWeb3Modal();

    const [formError, setFormError] = useState({});
    const [blockDiv, setBlockDiv] = useState('');
    const [userBalance, setUserBalance] = useState(false);

    useEffect(() => {
        if(params.ref) {
            localStorage.setItem("referrer_username", params.ref);
            if(takeToHome) {
                toast.success("Referred by " + params.ref + ". Taking to Home.");
                takeToHome && window.open(appConfig.websiteLink, '_self');
            } else {
                toast.success("Referred by " + params.ref);
            }
            setValue("referrer_username", params.ref);

        } else {
            takeToHome && window.open(appConfig.websiteLink, '_self');
            if(localStorage.getItem("referrer_username") !== null && localStorage.getItem("referrer_username") !== undefined) {
                toast.success("Referred by " + localStorage.getItem("referrer_username"));
                setValue("referrer_username", localStorage.getItem("referrer_username"));
            } else {
                setValue("referrer_username", "admin");
            }
        }
    }, []);

    //Check for authentication
    useEffect(() => {
        if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
            history('/dashboard');
        }
    }, [authContext]);

    //Get user balance
    useEffect(() => {
        if(walletContext.walletConnected === true) {
            (async () => {
                setUserBalance(await readContract.getUserBalance());
            })();
        }
    }, [walletContext.walletConnected]);

    const FormSchema = yup.object().shape({
        referrer_username: yup.string().max(20).label('Referrer Username')
    });

    const {
        getValues,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isValid }
    } = useForm({ mode: 'onChange', resolver: yupResolver(FormSchema) })

    const getReferrerAddress = async () => {
        setBlockDiv('registerNow');
        let formData = getValues();
        formData.address = walletContext.userAddress;
        const res = await callAPI("getReferrerAddress", formData);
        setFormError({});
        if(res.data.state === 1) { //Success
            await writeContract.ContractWrite({ writeFn: "Register", inputData: [res.data.referrerAddress], successCallback: registerSuccessCB });
            setBlockDiv('');
        } else { //Handle error
            setFormError(res.data.formError);
            toast.error(res.data.error);
            setBlockDiv('');
            console.log(res.data);
        }
    }

    const registerSuccessCB = (hash) => {
        return new Promise(resolve => {
            (async () => {
                const apiToPoll = () => callAPI("checkChainTransaction/main/" + hash);
                const pollContinueCondition = (res) => res.data.state === "pending";
                const pollRes = await pollResults(apiToPoll, pollContinueCondition, 5000);
                resolve();
                if(pollRes.data.state == "success") {
                    //Show success popup
                    Swal.fire({
                        title: "Registration Complete!",
                        text: "Registration completed successfully. You can now login.",
                        icon: 'success',
                        customClass: {
                            confirmButton: 'bg-gradient-two rounded-10 shadow-one text-md text-black font-bold h-[40px] px-10 mb-5'
                        },
                        buttonsStyling: false
                    }).then((result) => {
                        if (result.isConfirmed) {
                          history('/login/autoLogin');
                        }
                    });
                    await authContext.getAppBootstrap();
                } else if(pollRes.data.state == "failed") {
                    toast.error("Failed to register.");
                }
             })();
        });
    }
    
    const startRegistration = async () => {

        try {
            if(!walletContext.walletConnected) {
                await walletContext.connectWallet();
            }
            if(walletContext.walletConnected !== true) { throw 'Failed to connect to wallet'; }
            if(userBalance.int < 0.005) { throw 'Have atleast 0.005 BNB to cover gas cost'; }
            getReferrerAddress();
        } catch (error) {
            toast.error(error);
        }
    }
    
    return (
        <>
            <WelcomeBox layout='register' />
            <div className='w-full rounded-2xl_next bg-gradient-gold box-shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20)] border-[3px] border-[#222] px-4 py-8 lg:px-12  relative'>
            <img
                className='absolute right-0 top-0 z-0 w-[50%] max-w-[270px]'
                src={transLock}
                alt='transparent-img'
            />
            <div className='relative z-10 space-y-7'>
                <div className='w-[90px] md:w-[120px] mx-auto'>
                <img className='w-full' src={lock} alt='Lock' />
                </div>
                <div className='text-center space-y-3 w-[280px] mx-auto'>
                <p className='text-base text-black font-semibold'>
                    1. Connect BSC Wallet
                </p>
                <ButtonOne onClick={walletContext.connectWallet} disabled={walletContext.walletConnected}>
                    {walletContext.walletConnected ? 'Wallet Connected' : 'Connect Wallet'}
                </ButtonOne>
                <p className="text-dark text-sm font-bold mt-3 mb-6 cursor-pointer" onClick={web3modal.open}>
                    {walletContext.walletConnected ? 'Connected to : ' + helpers.trimAddress({ address: walletContext.userAddress, firstShow: 5, secondShow: 5 }) : "Connect your BEP-20 wallet"}
                </p>
                </div>
                {writeContract.modalShow ?
                    <PendingModal show={writeContract.modalShow} data={writeContract.modalData} />
                :
                    <div className='text-center space-y-3 w-[280px] mx-auto'>
                        <p className='text-base text-black font-semibold'>
                            2. Enter your Referrer
                        </p>
                        <form onSubmit={handleSubmit(startRegistration)}>
                            <input
                                type="text" 
                                {...register('referrer_username')}
                                placeholder="Referrer Username"
                                className="text-dark placeholder-[#666] rounded-10 bg-transparent px-6 py-3 w-full text-lg mb-5 border-2 border-[#222] focus:outline-none focus:border-dark transition duration-200 shadow-five"
                            />
                            {formError?.address && <p className="text-gray-200 bg-red-700 font-bold px-3 py-2 text-sm mb-5 text-center rounded-10">{formError?.address}</p>}
                            {errors.referrer_username?.message && <p className="text-gray-200 bg-red-700 font-bold px-3 py-2 text-sm mb-5 text-center rounded-10">{errors.referrer_username?.message}</p>}
                            {formError?.referrer_username && <p className="text-gray-200 bg-red-700 font-bold px-3 py-2 text-sm mb-5 text-center rounded-10">{formError?.referrer_username}</p>}
                            
                            <ButtonOne type="submit" disabled={!walletContext.walletConnected || !isValid || blockDiv === 'registerNow'} loading={blockDiv === 'registerNow' ? 1 :0}>
                                Register Now
                            </ButtonOne>
                        </form>
                    </div>
                }
            </div>
            </div>
        </>
    );
};

export default Register;