import React from "react";
import mike from "../../../Assets/mike.png";
import { useUserAuth } from "../../../Contexts/authContext";
import { CardBlack, TitleSecondary } from "../../../Utility/Utility";
import CopyLink from "./CopyLink";

const ReferDashBlock = () => {

  const auth = useUserAuth();

  return (
    <CardBlack>
      <div className="p-6 md:p-8 grid lg:grid-cols-[250px_auto] gap-8 items-center">
        <div className="text-center">
          <img className="max-w-[200px] mx-auto" src={mike} alt="Mike" />
        </div>
        <div>
          <div className="md:flex justify-between items-start mb-6 space-y-3 md:space-y-0">
            <TitleSecondary>
              Your Unique Referral Link
            </TitleSecondary>
          </div>
          <div className="space-y-3">
            <CopyLink
              title="Link to Home Page"
              link={auth?.userBootstrap?.userData?.userRefLinkHome}
            />
            <CopyLink
              title="Link to Register Page"
              link={auth?.userBootstrap?.userData?.userRefLinkReg}
            />
          </div>
        </div>
      </div>
    </CardBlack>
  );
};

export default ReferDashBlock;
