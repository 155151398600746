import { Chip } from "@material-tailwind/react";
import React from "react";
import bnb from "../../../Assets/bnb.png";
import nullimg from "../../../Assets/null.png";
import recycle from "../../../Assets/recycle.png";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../../Utility/Utility";

const CyclesTable = ({ data }) => {

    return (
        <>
            <div className="mb-6">
                <TitlePrimary>Pack Cycles</TitlePrimary>
            </div>
            {data && data.length > 0 &&
                <>
                    <div className="h-full w-full overflow-scroll shadow-xl border-2 border-dark rounded-10">
                        <table className="w-full min-w-max table-auto text-left overflow-hidden text-gray-200">
                            <thead>
                                <tr className="bg-gradient-gold text-gray-900">
                                    <th className="text-sm py-4 px-5">Pack #</th>
                                    <th className="text-sm py-4 px-5">Left Leg #</th>
                                    <th className="text-sm py-4 px-5">Right Leg #</th>
                                </tr>
                            </thead>
                            <tbody>
                            {data && data.length > 0 && data.map((e, i) => {
                                return (
                                    <tr key={i} className={`odd:bg-[#000] bg-[#111] border-b border-[rgb(8,14,26)] text-sm hover:bg-gradient-gold hover:text-[#000]`}>
                                        <td className="px-5 py-4">{e.id} {e.isCycle == 'no' ? <Chip className="inline ml-3" color='amber' value='Parent' /> : <Chip className="inline ml-3" color='gray' value='Child' />}</td>
                                        <td className="px-5 py-4"><img src={bnb} className="w-5 inline mr-1" /> {e.leg1 !== null ? <>#{e.leg1}</> : <img src={nullimg} className="inline w-5 opacity-40" />}</td>
                                        <td className="px-5 py-4"><img src={recycle} className="w-5 inline mr-1" /> {e.leg2 !== null ? <>#{e.leg2}</> : <img src={nullimg} className="inline w-5 opacity-40" />}</td>
                                    </tr>
                                )
                            })}
                            </tbody>
                        </table>
                    </div>
                </>
            }
            {data && data.length === 0 &&
                <EmptyRecords title='No Cycles Found' description='Your cycled packs will be listed here.' />
            }
            {data === false &&
                <LoadingData />
            }
        </>
    );
};

export default CyclesTable;
