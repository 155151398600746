import React from "react";
import hero1 from '../../../Assets/hero1.png';

const ActivePacks = ({ stats }) => {

  return (
    <div className="relative z-10 shadow-lg bg-[#000] flex text-center items-center justify-between px-4 py-4 rounded-10 mb-5">
      <div className="text-left pl-3">
        <div className="font-bold text-4xl text-primary font-Bungee">{stats.userStats.totalActivePacks}/{stats.userStats.totalPurchasedPacks} <span className="text-lg text-white">Packs Active</span></div>
        <div className="mt-2 font-normal text-sm text-white">Purchase more packs to keep earning without any interruption.</div>
      </div>
      <div className="sm:text-left sm:mr-3">
        <img src={hero1} className="animate-spin mx-auto sm:mx-0 w-full max-w-[70px] sm:max-w-[70px]" />
      </div>
    </div>
  );
};

export default ActivePacks;
