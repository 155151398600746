import React from "react";
import { Link } from "react-router-dom";
import bnb from "../../../Assets/bnb.png";
import user from "../../../Assets/member.png";
import nullimg from "../../../Assets/null.png";
import recycle from "../../../Assets/recycle.png";
import { CardBlack } from "../../../Utility/Utility";

const PackItem = ({ pack, showButton = true }) => {

  return (
    <CardBlack>
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h4 className="text-xl lg:text-2xl font-Bungee text-white uppercase m-0 tracking-[.9px]">
            Pack #{pack.id}
          </h4>
          {pack.expired === 'yes' ?
            <button className="bg-red-900 rounded-[10px] border-1 border-black text-white text-sm font-semibold uppercase tracking-[.6px] px-5 py-2">
              Expired
            </button>
          :
            <button className="bg-btn-gradient-gold rounded-[10px] border-1 border-black text-black text-sm font-semibold uppercase tracking-[.6px] px-5 py-2">
              Active
            </button>
          }
        </div>
        <div className="tree">
          <div className="tree-rw text-center">
            <div className="member w-[150px] mx-auto">
              <p className="text-white text-base font-medium tracking-[.75px] mb-2">
                Me
              </p>
              <img
                className="mx-auto w-[70px] h-[70px] rounded-full"
                src={user}
                alt="user"
              />
            </div>
          </div>
          <div className="text-center my-2">
            <svg
              width="274"
              height="89"
              viewBox="0 0 274 89"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="mx-auto"
            >
              <path
                d="M1 88.168V55.668H137M273 88.168V55.668H137M137 55.668V0.167969"
                stroke="white"
                strokeWidth="2"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div className="tree-rw two relative flex justify-between text-center mx-auto">
            <div className="member w-[150px] relative">
              
              {pack.leg1*1 > 0 ?
                <>
                  <p className="text-white text-base font-medium tracking-[.75px] mb-2">
                    #{pack.leg1}
                  </p>
                  <img
                    className="mx-auto w-[50px] h-[50px] rounded-full"
                    src={user}
                    alt="user"
                  />
                </>
              :
                <>
                  <p className="text-gray-700 text-base font-medium tracking-[.75px] mb-2">
                    empty
                  </p>
                  <img
                    className="mx-auto w-[50px] h-[50px] opacity-40"
                    src={nullimg}
                    alt="user"
                  />
                </>
              }

              <button className="absolute left-3 -bottom-7">
                <img className="w-8" src={bnb} alt="" />
              </button>
            </div>
            <div className="member w-[150px] relative">
              {pack.leg2*1 > 0 ?
                <>
                  <p className="text-white text-base font-medium tracking-[.75px] mb-2">
                    #{pack.leg2}
                  </p>
                  <img
                    className="mx-auto w-[50px] h-[50px] rounded-full"
                    src={user}
                    alt="user"
                  />
                </>
              :
                <>
                  <p className="text-gray-700 text-base font-medium tracking-[.75px] mb-2">
                    empty
                  </p>
                  <img
                    className="mx-auto w-[50px] h-[50px] opacity-40"
                    src={nullimg}
                    alt="user"
                  />
                </>
              }
              <button className="absolute right-3 -bottom-7">
                <img className="w-8" src={recycle} alt="" />
              </button>
            </div>
          </div>
          <div className="total-wrapper flex justify-around mt-20">
            <div className="total w-[150px] text-center">
              <p className="text-white text-md tracking-[.6px] opacity-70 font-medium mb-1 leading-[1.4]">
                Total Earned <br />
                From Pack
              </p>

              <strong className="font-Bungee text-[#E3FF93] text-[34px]">
                ${(pack.totalEarned*1).toFixed(0)}
              </strong>
            </div>
            <div className="total w-[150px] text-center">
              <p className="text-white text-md tracking-[.6px] opacity-70 font-medium mb-1 leading-[1.4]">
                Total Times <br />
                Pack Cycled
              </p>
              <strong className="font-Bungee text-[#E3FF93] text-[34px]">
                {pack.totalCycles}
              </strong>
            </div>
          </div>
          {showButton &&
            <div className="mt-5">
              <Link to={'/packs/' + pack.id}>
                <button className="bg-btn-gradient-gold w-full rounded-[10px] border-1 border-black text-black text-base font-bold tracking-[.6px] px-4 py-3">
                  View Cycles & Payments
                </button>
              </Link>
            </div>
          }
        </div>
      </div>
    </CardBlack>
  );
};
export default PackItem;
