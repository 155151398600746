//Import CSS
import { useUserAuth } from '../Contexts/authContext';
import appConfig from '../Utility/Config/config';
import AuthLayout from "./AuthLayout";
import DashboardLayout from "./DashboardLayout";
import LayoutLoading from "./LayoutLoading";

export default function Layout() {  
    const userAuth = useUserAuth();
    return (
        <>
            {userAuth.appBootstrapped && userAuth.loggedIn === false && <AuthLayout />}
            {userAuth.appBootstrapped && userAuth.loggedIn === true && <DashboardLayout />}
            {!userAuth?.appBootstrapped && <LayoutLoading />}
            <div className="bg-primary/90 text-center border-2 border-t-dark py-4 px-5 text-sm font-semibold text-gray-900">
                <div className="">&copy; MyInfinity.io | All Rights Reserved - v{appConfig.version}</div>
            </div>
        </>
    );
}