import { useWeb3Modal } from '@web3modal/react';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { WelcomeBox } from '../../Components/auth/WelcomeBox';
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from '../../Contexts/walletContext';
import { useCallAPI } from '../../Hooks/callAPI';
import { useHelpers } from '../../Hooks/helpers';
import { useReadContract } from '../../Hooks/readContract';
import { useWriteContract } from '../../Hooks/writeContract';
import lock from "../../Assets/lock.png";
import transLock from "../../Assets/trans-lock.png";
import { ButtonOne } from '../../Utility/Buttons';

const Login = ({ autoLogin = false }) => {
    const callAPI = useCallAPI();
    const history = useNavigate();
    const helpers = useHelpers();
    const authContext = useUserAuth();
    const walletContext = useWalletContext();
    const readContract = useReadContract();
    const writeContract = useWriteContract();
    const web3modal = useWeb3Modal();

    const [blockDiv, setBlockDiv] = useState('');

    //Check for authentication
    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === true) {
          history('/dashboard');
          return;
      }
    }, [authContext]);

    useEffect(() => {
      if(authContext.appBootstrapped === true && authContext.loggedIn === false && autoLogin) {
        loginNow();
        return;
      }
    }, [authContext, walletContext.walletConnected]);
    
    const loginNow = async () => {

        setBlockDiv('loginNow');
        try {
            //Check if the address is registered in the users contract
            const userRes = await readContract.read('isUserExists', [walletContext.userAddress]);
            if(userRes !== true) { throw 'Register first'; }

            const res = await callAPI("getLoginNonce", { address: walletContext.userAddress });
            if(res.data.state !== 1) { throw res.data.error };
            if(res.data.state === 1) { //Success
                //Sign message with nonce to login
                await writeContract.SignMessage(res.data.nonce, postLogin);
                setBlockDiv('');
            }
        } catch (error) {
            console.log(error);
            try {
              toast.error(error);
            } catch (error) {
              toast.error('Unexpected error occurred E001');
            }
            setBlockDiv('');
        }
        
    }

    const postLogin = async (signatureHash) => {
        //Send the hash signature to verify and login this user
        const loginRes = await callAPI("verifyLogin", { address: walletContext.userAddress, signature: signatureHash });
        if(loginRes.data.state !== 1) { toast.error(loginRes.data.error) }
        if(loginRes.data.state === 1) { //Success
            localStorage.setItem('userauthtoken', loginRes.data.auth_token);
            await authContext.getAppBootstrap();
            history('/');
        }
    }

    return (
      <>
        <WelcomeBox layout='login' />
        <div className='w-full rounded-2xl_next bg-gradient-gold box-shadow-[0px_11px_15px_0px_rgba(0,0,0,0.20)] border-[3px] border-[#222] px-4 py-8 lg:px-12  relative'>
          <img
            className='absolute right-0 top-0 z-0 w-[50%] max-w-[270px]'
            src={transLock}
            alt='transparent-img'
          />
          <div className='relative z-10 space-y-7'>
            <div className='w-[90px] md:w-[120px] mx-auto'>
              <img className='w-full' src={lock} alt='Lock' />
            </div>
            <div className='text-center space-y-3 w-[280px] mx-auto'>
              <p className='text-base text-black font-semibold'>
                1. Connect BSC Wallet
              </p>
              <ButtonOne onClick={walletContext.connectWallet} disabled={walletContext.walletConnected}>
                {walletContext.walletConnected ? 'Wallet Connected' : 'Connect Wallet'}
              </ButtonOne>
              <p className="text-dark text-sm font-bold mt-3 mb-6 cursor-pointer" onClick={web3modal.open}>
                {walletContext.walletConnected ? 'Connected to : ' + helpers.trimAddress({ address: walletContext.userAddress, firstShow: 5, secondShow: 5 }) : "Connect your BEP-20 wallet"}
              </p>
            </div>
            <div className='text-center space-y-3 w-[280px] mx-auto'>
              <p className='text-base text-black font-semibold'>
                2. Authenticate Wallet
              </p>
              <ButtonOne onClick={loginNow} disabled={!walletContext.walletConnected || blockDiv === 'loginNow'} loading={blockDiv === 'loginNow' ? 1 : 0}>
                Login Now
              </ButtonOne>
            </div>
          </div>
        </div>
      </>
    );
};

export default Login;
