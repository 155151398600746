import "./index.css";

import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';

import "@fontsource/inria-sans";
import "@fontsource/inria-sans/400.css";
import "@fontsource/inria-sans/700.css";
import "@fontsource/ibm-plex-sans";
import "@fontsource/ibm-plex-sans/500.css";
import "@fontsource/ibm-plex-sans/600.css";
import "@fontsource/ibm-plex-sans/700.css";

import 'react-circular-progressbar/dist/styles.css';
import 'react-spring-bottom-sheet/dist/style.css';
import '@ionic/react/css/core.css';

import { ThemeProvider } from "@material-tailwind/react";

import ReactGA from "react-ga4";
import reportWebVitals from './reportWebVitals';
import appConfig from "./Utility/Config/config";

const root = ReactDOM.createRoot(document.getElementById('root'));
ReactGA.initialize(appConfig.gaSiteCode);

root.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>
);
const SendAnalytics = ()=> {
  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
  });
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(SendAnalytics);