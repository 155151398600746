import { useState } from 'react';
import { useHelpers } from './helpers';
import appConfig from '../Utility/Config/config';
import { BottomSheet } from 'react-spring-bottom-sheet'
import PuffLoader from "react-spinners/PuffLoader";
import { BarLoader } from 'react-spinners';

export const usePendingModal = () => {

    const [show, setShow] = useState(false);
    const [data, setData] = useState('');

    const showModal = (data) => {
        setShow(true);
        setData(data);
    }

    const hideModal = () => {
        setShow(false);
    }

    return [show, data, showModal, hideModal];
}

export const PendingModal = ({ show, data }) => {

    const helpers = useHelpers();

    return (
        <div className='text-center'>
            <BarLoader
                color='#000'
                loading={true}
                size={60}
                aria-label="Loading"
                data-testid="loader"
                className='mb-5 mx-auto'
            />
            <h1 className="text-lg text-dark mb-3 font-bold animate-pulse">Processing Transaction</h1>
            <p className="text-sm text-[#000]/70 max-w-[390px] mx-auto font-normal">
                Txn Hash : <b><helpers.trimAddress address={data} copy /></b> <br/>Transaction will be processed in 1-2 mins.
            </p>
        </div>
    )
}