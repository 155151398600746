import { Button, Chip } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserAuth } from '../../Contexts/authContext';
import { useWalletContext } from "../../Contexts/walletContext";
import { useCallAPI } from "../../Hooks/callAPI";
import { useHelpers } from "../../Hooks/helpers";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";

const Payments = () => {

    const auth = useUserAuth();
    const helper = useHelpers();
    const callAPI = useCallAPI();
    const walletContext = useWalletContext();

    const [data, setData] = useState([]);
    const [dataLoadFull, setDataLoadFull] = useState(false);

    //Get user balance
    useEffect(() => {
        if(auth.appBootstrapped === true && walletContext.walletConnected === true) {
            getResults(0);
        }
    }, [auth, walletContext.walletConnected]);

    const getResults = async (offset) => {
        const res = await callAPI("getPayments" + "/" + offset);
        console.log(res);
        if(res.data !== false) {
          setData([...data, ...res.data]);
        } else {
            toast.success("All Rows Loaded");
            setDataLoadFull(true);
        }
    }

    return (
        <>
            <div className="relative z-10">
                <div className="mb-6">
                    <TitlePrimary>Payments</TitlePrimary>
                </div>
                {data && data.length > 0 &&
                    <>
                        <div className="h-full w-full overflow-scroll shadow-xl border-2 border-dark rounded-10">
                            <table className="w-full min-w-max table-auto text-left overflow-hidden text-gray-200">
                                <thead>
                                    <tr className="bg-gradient-gold text-gray-900">
                                        <th className="text-sm py-4 px-5">From User</th>
                                        <th className="text-sm py-4 px-5">From Pack #</th>
                                        <th className="text-sm py-4 px-5">Parent Pack #</th>
                                        <th className="text-sm py-4 px-5">Amount</th>
                                        <th className="text-sm py-4 px-5">Type</th>
                                        <th className="text-sm py-4 px-5">Hash</th>
                                        <th className="text-sm py-4 px-5">Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {data && data.length > 0 && data.map((e, i) => {
                                    return (
                                        <tr key={i} className={`odd:bg-[#000] bg-[#111] border-b border-[rgb(8,14,26)] text-sm hover:bg-gradient-gold hover:text-[#000]`}>
                                            <td className="px-5 py-4">{e.from_id*1 !== 0 ? <>ID {e.from_id}</> : <>System</>}</td>
                                            <td className="px-5 py-4">#{e.pack_id}</td>
                                            <td className="px-5 py-4">#{e.parent_pack}</td>
                                            <td className="px-5 py-4"><helper.AmountToCurrency amount={e.amount} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color='amber' value={helper.capitalize(e.type)} /></td>
                                            <td className="px-5 py-4"><Chip className="inline" color='gray' value={helper.trimAddress({ address: e.hash, copy: true })} /></td>
                                            <td className="px-5 py-4">{helper.ProcessDate(e.created)}</td>
                                        </tr>
                                    )
                                })}
                                </tbody>
                            </table>
                        </div>
                        {!dataLoadFull && <Button className="mt-4" onClick={() => getResults(data.length)}>Load More</Button>}
                    </>
                }
                {data && data.length === 0 &&
                    <EmptyRecords title='No Activity Found' description='Your activities will be listed here.' />
                }
                {data === false &&
                    <LoadingData />
                }
            </div>
            
        </>
    );
};

export default Payments;
