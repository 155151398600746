import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import PackItem from "../../Components/dash/packs/PackItem";
import { useCallAPI } from "../../Hooks/callAPI";
import { EmptyRecords, LoadingData, TitlePrimary } from "../../Utility/Utility";
import CyclesTable from "../../Components/dash/packs/CyclesTable";
import PaymentsTable from "../../Components/dash/packs/PaymentsTable";

const PackDetails = () => {

    const callAPI = useCallAPI();
    const params = useParams();
    const navigate = useNavigate();

    const[pack, setPack] = useState(false);
    const[payments, setPayments] = useState(false);
    const[cycles, setCycles] = useState(false);

    useEffect(() => {
        getPackData();
    }, []);

    const getPackData = async () => {
        if(params.id === undefined) {
            toast.error('Invalid Pack ID');
        }

        const res = await callAPI("getPackData/" + params.id);      
        if(res.status && res.data) {
            res.data.info ? setPack(res.data.info) : setPack({});
            res.data.payments ? setPayments(res.data.payments) : setPayments([]);
            res.data.cycles ? setCycles(res.data.cycles) : setCycles([]);
        } else {
            setPack({});
            setPayments([]);
            setCycles([]);
        }
    }

    return (
        <>
            <div className="relative z-10">
                <div className="mb-2 flex items-start">
                    <ArrowLeftIcon onClick={() => navigate('/packs')} className="w-8 text-gray-900 mr-2" />
                    <TitlePrimary>Viewing Pack #{pack?.id}</TitlePrimary>
                </div>
                <div className="space-y-6">
                    {pack.expired === 'yes' &&
                        <div className="bg-red-900 text-center rounded-[10px] border-1 border-black text-white text-md font-semibold px-5 py-2">
                            Your pack has expired. <br/><span className="font-normal text-sm">Packs expire when your purchased pack cycles twice. Buy more packs to earn more.</span>
                        </div>
                    }
                    {pack &&
                        <>
                            {pack.id !== null && 
                                <div className="xl:grid xl:grid-cols-[30%_70%] xl:space-x-7">
                                    <div className='mb-4'>
                                        <PackItem pack={pack} showButton={false} />
                                    </div>
                                    <div className="">
                                        <div className="mb-7">
                                            <CyclesTable data={cycles} />
                                        </div>
                                        <div className="">
                                            <PaymentsTable data={payments} />
                                        </div>
                                    </div>
                                </div>
                            }
                            {pack.id === null && 
                                <EmptyRecords title='Pack not found' description='This Pack is not purchased yet. Try with a different ID.' />
                            }
                        </>
                    }
                    {pack === false &&
                        <LoadingData />
                    }
                </div>
            </div>
        </>
    )
}

export default PackDetails;