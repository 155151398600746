import { useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Link } from "react-router-dom";
import logoLong from "../../../Assets/logo-long.png";
import logo from "../../../Assets/logo.png";
import telegram from "../../../Assets/telegram.png";
import { Overlay } from "../../../Utility/Utility";
import Drawer from "./Drawer";
import { NavDash } from "./NavDash";
import UserDetailsDash from "./UserDetailsDash";

const HeaderDash = () => {

  const [drawer, setDrawer] = useState(false);
  const isSmallScreen = useMediaQuery({ maxWidth: 1023 });

  const closeDrawer = () => {
    setDrawer(false);
  };

  return (
    <header className="bg-[#1B1B1B] px-4 py-4 md:px-6">
      <Overlay overlay={drawer && isSmallScreen} />
      <div className="flex items-center justify-between relative z-50">
        <Link>
          <img
            src={logoLong}
            alt="logo"
            className="max-h-[60px] max-w-[280px] xl:max-w-[300px] hidden lg:block"
          />
          <img src={logo} alt="logo" className="h-[50px] lg:hidden" />
        </Link>
        <div className="hidden lg:block ml-auto mr-6 space-y-5">
          <div className="flex items-center justify-end space-x-5 xl:space-x-6">
            <UserDetailsDash />
          </div>
          <ul className="main-menu flex items-center justify-end space-x-6 xl:space-x-10 [&>li>a]:text-black [&>li>a]:text-white">
            <NavDash />
          </ul>
        </div>
        <div
          className={`${
            drawer && isSmallScreen ? "translate-x-0" : "translate-x-full"
          } fixed h-screen w-[290px] bg-black right-0 top-0 z-50 p-6 transition duration-300`}
        >
          <Drawer onCloseDrawer={closeDrawer} />
        </div>

        <button
          onClick={() => setDrawer(true)}
          className="mobile-bar ml-auto mr-4 lg:hidden"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="#ffffff"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="w-9 h-9 transition duration-300"
          >
            <line x1="3" y1="12" x2="21" y2="12"></line>
            <line x1="3" y1="6" x2="21" y2="6"></line>
            <line x1="3" y1="18" x2="21" y2="18"></line>
          </svg>
        </button>
        <Link to={'/tgAlerts'} className="w-[50px] lg:w-[70px]">
          <img className="max-w-full" src={telegram} alt="telegram" />
        </Link>
      </div>
    </header>
  );
};

export default HeaderDash;